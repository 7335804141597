/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import Layout from '@lekoarts/gatsby-theme-cara/src/components/layout';
import MenuBar from '../@lekoarts/gatsby-theme-cara/components/menu-bar';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import theme from '../@lekoarts/gatsby-theme-cara/constants/theme.js';
import SEO from '@lekoarts/gatsby-theme-cara/src/components/seo';
import BlogBackground from '../@lekoarts/gatsby-theme-cara/components/blog-background';
import GenericFooter from '../@lekoarts/gatsby-theme-cara/components/generic-footer';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Card } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles({
  card: {
    maxWidth: 690,
    backgroundColor: '#2D3748',
    textAlign: 'left',
  },
  unstyled: {
    color: 'white',
  },
});

const Blog = ({ data }) => {
  const classes = useStyles();
  const getImage = slug =>
    data.allFile.edges.find(image => image.node.relativePath.includes(slug));
  return (
    <ThemeProvider theme={theme}>
      <SEO title="Blog" />
      <Layout className="Blog-post-page">
        <MenuBar />
        <BlogBackground offset={0} />
        <div className="Pricing-content text-center">
          <h1>Blog</h1>
          <div
            sx={{
              display: `grid`,
              gridGap: [4, 4, 4, 4],
              gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`, `repeat(3, 1fr)`],
            }}
          >
            {data.allMdx.edges.map((post, index) => (
              <Card key={index} className={classes.card}>
                <Link to={post.node.fields.slug}>
                  <CardActionArea>
                    <CardHeader
                      title={post.node.frontmatter.title}
                      className={classes.unstyled}
                    />
                    <Img
                      fluid={
                        getImage(post.node.fields.slug.replace('/blog/', '')).node
                          .childImageSharp.fluid
                      }
                      className="Card__media"
                    />
                  </CardActionArea>
                </Link>
                <CardContent>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {post.node.excerpt}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Link to={post.node.fields.slug}>
                    <Button variant="outlined" size="small" color="primary">
                      Read More
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            ))}
          </div>
        </div>
        <GenericFooter />
      </Layout>
    </ThemeProvider>
  );
};

export default Blog;

export const query = graphql`
  query {
    allMdx(skip: 4, sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/blog-images/" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 345) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
